@import 'normalize.less';
@import 'slick.less';
@import 'video-js.less';
@import 'flexbox.less';
@import 'fonts.less';
@import 'variables.less';
@import 'helpers.less';


* { margin: 0; padding: 0; text-rendering: optimizeSpeed; }

html, body {
  height: 100%;
  .hide-scrollbars();
}

.slick-changing {
  * {
    //transition:none !important;
  }
}

body {
  //padding: 3.125vw;
  font-size: 100%;
  .font-text();
  //min-height: 100vh;
  scroll-behavior: smooth;
  overflow-x: hidden;
  
  &.init {
    main {
      visibility: hidden;
    }
  }
  &[data-text-color="white"] {

      main, .main-feature {
        color: #fff;
        * {
          color: #fff;
        }
        a {
          .inverse-link();
        }
        li.active .link--hub.active {
          color: #fff !important;
          &:hover {
            color: #000 !important;
          }
        }
        .slick-caption {
          color: #fff;
          a {
            .inverse-link();
          }
        }
        svg {
          fill: #fff;
          g {
            fill: #fff;
          }
          line {
            stroke: #fff;
          }
        }
      }
    
    
  }
}

body[data-body-template="home"],
body[data-body-template="project"] {
  overflow-y: hidden;
}

//* { .font(); }

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
}

figure {
  display: block;
  img {
    width: 100%;
    display: block;
  }
}

svg:not(.module--svg-text) {
  width: 100%;
  height: auto;
  //height: 0.85em;
  display: block;
}

.list-li-link {
  .flex-display();
  .justify-content(space-between);
  margin: 0;
  position: relative;
  span {
    margin: 0 !important;
    padding: 0;
    line-height: 1.02 !important;
    &.no-wrap {
      text-overflow: ellipsis;
      overflow: hidden;
      width: 82%;
      
    }
    
  }
}


@import 'lockup.less';

/* ============================= 

          BACKFACE

================================ */
#Backface {
  position: fixed;
  z-index: 2;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  display: none;

  @-moz-document url-prefix() {
    background: rgba(255, 237, 0, .94);
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    background: rgba(255, 237, 0, .94);
  }
  
  .show-menu &, 
  .reveal-menu & {
    display: block;
    z-index: 3;
  }
  .reveal-menu & {
    
  }
}

.splash-logo {
  
  position: fixed;
  bottom: 0;
  width: 100%;
  .splash-logo-inner {
    .margins();
  }
  svg {
    
  }
}

header.coming-soon {
  h1 {
    text-align: left;
    .margins();
  }
}

/* ============================= 

          ANIMATION

================================ */

@-webkit-keyframes fadeOut {
  0% { opacity: 1;}
  75% { opacity: 1 }
  99% { opacity: 0.01;}
  100% { opacity: 0;}
}  
@keyframes fadeOut {
  0% { opacity: 1;}
  75% { opacity: 1 }
  99% { opacity: 0.01;}
  100% { opacity: 0;}
}

@-webkit-keyframes fadeOutRTE {
  0% { opacity: 1;}
  100% { opacity: 0;}
}  
@keyframes fadeOutRTE {
  0% { opacity: 1;}
  100% { opacity: 0;}
}


@-webkit-keyframes fadeIn {
  0% { opacity: 0;}
  75% { opacity: 0.01 }
  100% { opacity: 1;}
}  
@keyframes fadeIn {
  0% { opacity: 0;}
  75% { opacity: 0.01 }
  100% { opacity: 1;}
}

@-webkit-keyframes fadeInNormal {
  0% { opacity: 0;}
  100% { opacity: 1;}
}  
@keyframes fadeInNormal {
  0% { opacity: 0;}
  100% { opacity: 1;}
}

@-webkit-keyframes fadeInOut {
  0% { opacity: 0;}
  15% { opacity: 1 }
  75% { opacity: 1;}
  100% { opacity: 0;}
}  
@keyframes fadeInOut {
  0% { opacity: 0;}
  15% { opacity: 1 }
  75% { opacity: 1;}
  100% { opacity: 0;}
}

@-webkit-keyframes contentFade {
  0% { opacity: 1;}
  100% { opacity: .8;}
}  
@keyframes contentFade {
  0% { opacity: 1;}
  100% { opacity: .8;}
}

@-webkit-keyframes slideDown {
  0% { max-height: 0;}
  100% { max-height: 100vh; }
}  
@keyframes slideDown {
  0% { max-height: 0;}
  100% { max-height: 100vh; }
}  

.fade-out {
  -webkit-animation: fadeOut 2s;
  animation: fadeOut 2s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
}

.fade-in {
  -webkit-animation: fadeIn 1.5s;
  animation: fadeIn 1.5s;
  animation-fill-mode: forwards;
  .fade-in {
    animation-delay: 0s;
  }
  &.fade-in-fast {
    -webkit-animation: fadeIn .5s;
    animation: fadeIn .5s;
  }
}

.fade-in-normal {
  -webkit-animation: fadeInNormal .25s;
  animation: fadeInNormal .25s;
  animation-fill-mode: forwards;
}

.fade {
  //opacity: 0;
  -webkit-animation: fadeInOut 2s;
  animation: fadeInOut 2s;
  animation-delay: 0s;
  animation-fill-mode: forwards;
}

.content-fade {
  -webkit-animation: contentFade .25s;
  animation: contentFade .25s;
  animation-fill-mode: forwards;
}

body {
  //transition: .15s background;
}

body.show-menu, body.blur-section-only, body.reveal-menu {
  background: @color_gray_bg;
  [data-barba-namespace="project"] .project--slide:not(.bg--yellow) {
    background: @color_gray_bg;
  }
  .program-blocks, .reflection-blocks {
    .content-fade();
  }

  [data-barba="container"], .main-feature {
    //height: 100vh;
    //position: relative;
  }
}

/* ============================= 

          HELPERS/MIXINS

================================ */


.flex-container-center {
  .flex-display();
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 100%;
}

.margins {
  padding: @margin*.65 @margin;
  margin: 0;
  @media @desktop {
    padding: @desktop_margin*.65 @desktop_margin;
  }
}

.rounded {
  border-radius: @rounded_unit/2;
  border-width: 1px;
  @media @desktop {
    border-radius: @rounded_unit;
    border-width: 2px;
  }
}

.filter-blur {
  filter: blur(30px);
  //transition: 1s filter linear;
}

.blur {
  .main-feature,
  .menu--footer {
    .filter-blur();
  } 
}

.main-feature.removing {
  .filter-blur();
  pointer-events: none;
}

.no-pointer {
  pointer-events: none;
}

.top-position {
  margin-top: @mobile_header_height;
  @media @desktop {
    margin-top: 0px;
  }
}

.blur-section-only {
  .main-feature:not([data-template="project"]) > section, .icon--close {
    .filter-blur();
  }
}

.blur-section-only-not-close {
  main [data-barba-namespace] > section, .main-feature > section, .menu--footer {
    .filter-blur();
  }
}

.blur-all {
  .blur-section-only();
  .menu--footer, .icon--close, .program--counter { .filter-blur(); }
}

main [data-barba-namespace] > section, .main-feature > section { will-change: filter; }

canvas {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
}

main section {
  
  /*
  -webkit-transition: .25s -webkit-filter linear;
  -moz-transition: .25s -moz-filter linear;
  -moz-transition: .25s filter linear;
  -ms-transition: .25s -ms-filter linear;
  -o-transition: .25s -o-filter linear;
  transition: .25s filter linear, 1s -webkit-filter linear;
  */
 
}

body.show-menu, body.reveal-menu {
  //.blur();
  overflow-y: hidden;
  main[data-barba="container"] {
    pointer-events: none;
  }
}

body.loading {
  .main-feature {
    cursor: wait;
  }
}

.inverse-link {
  color: #fff; 
  &:hover, &.active {
    color:#000 !important;
  }
}

.cursor-left:not(.loading) {

    cursor: url(@left_arrow), pointer;
    
    .slick {
      .rte {
        a, button { cursor: pointer;}
      }
    }
    &[data-text-color="white"] {
      cursor: url(@left_arrow_white), pointer;
      * { cursor: url(@left_arrow_white), pointer; }
    }
}

.cursor-right:not(.loading) {
    cursor: url(@right_arrow), pointer;
    
    .slick {
      .rte {
        a, button { cursor: pointer;}
      }
    }
    &[data-text-color="white"] {
      cursor: url(@right_arrow_white), pointer;
      * { cursor: url(@right_arrow_white), pointer; }
    }
}

.no-select {
  -webkit-user-select: none;  /* Chrome all / Safari all */
  -moz-user-select: none;     /* Firefox all */
  -ms-user-select: none;      /* IE 10+ */
  user-select: none;          /* Likely future */    
}

/* ============================= 

          FOG CANVAS

================================ */


#FogCanvas{
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
}

/* ============================= 

          SLICK

================================ */

.slick-slider, .slick-list {
  height: 100%;
}
.slick-slide > div { height: 100%; width: 100%; }
.slick-track {
  .flex-display();
  .flex-direction(row);
  .flex-wrap(no-wrap);
  height: 100%;
}
.slick-track:before, .slick-track:after {
  display: none;
}
.slick-track .slick-slide {
  .flex-display();
  height: 100%;
  .project--slide {
    height: 100%;
    .flex-display();
    .rte {
      text-align: left;
    }
  }
}

.slick-slide {
  //opacity: 0;
  &.slick-active, &.slick-current {
    opacity: 1;
  }
}

.slick iframe {
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.slick {
  .plyr__video-wrapper, .plyr--video, video, .video-js {
    height: 100%;
    width: 100%;
    pointer-events: none;
  }
}

.slick-arrow {
  position: absolute;
  z-index: 2;
  top: 50%;
  &.slick-next {
    right: 0;
  }
}

.slick--arrow {
  width: 4.589%;
  position: absolute;
  z-index: 2;
  top: 50%;
  background: none;
  transform: translate(0, -50%);
  left: 11%;
  margin: 0;
  &.slick--arrow-right {
    right: 11%;
    left: auto;
  }
  svg {
    width: 100%;
  }
}

.slick-slide {
  position: relative;
  figure {
    opacity: 0;
    transition: @transition_speed_content_in opacity ;
    &.lazyloaded {
      .fade-in-normal();
      opacity: 1;
    }
  }
}

.slick--meta {
  position: absolute;
  bottom: 0;
  left: 0;
  //mix-blend-mode: multiply;
  width: 100%;
  animation-delay: 2s;
  opacity: 0;
  @media @desktop {
    animation-delay: 0s;
  }
  &:hover {
    //mix-blend-mode: multiply;
  }
  .slick--caption, .slick--counter {
    opacity: 1;
    white-space: nowrap;
  }
  &.slick--meta-video {
    pointer-events: none;
  }
}

.slick--counter {
  &.fade-in {
    [data-template="home"] & {
      opacity: 0;
      animation-delay: 2s;
    }
  }
}

.slick--counter, .reflection--category {
  position: fixed;
  right: 0;
  top: 0;
  .h2();
  z-index: 2;
  [data-barba-namespace="home"] & {
    top: auto;
    bottom: 0;
    //animation-delay: 2s;
    position: absolute;
  }
}

.slick--caption {
  margin: 0 auto;
  position:  absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  .margins();
  opacity: 0;
  animation-delay: 1.8s;
  //text-transform: capitalize;
  a {
    cursor: pointer !important;
    //text-transform: capitalize;
    
  }
  @media @desktop {
    margin-left: 20vw;
    
  }
}


.figure--bg {
  width: 100%;
  height: 100%;
  width: calc(100% - (@margin * 2));
  height: calc(100% - (@margin * 2));
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin: @margin;
  margin-top: @mobile_header_height;
  margin-bottom: 0;
  background-size: contain;
  @media @desktop {
    margin-top: 0;
    
  }

  @media @mobile_landscape {
    height: calc(100% - (@margin * 2 + @mobile_header_height * 2) - 15px);
    margin-top: @mobile_header_height * 2 + 10;
    margin-bottom: 0;
  }
  
  /*
  [data-size="full_bleed"] & {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  */
  &.figure--landscape {

  }
  &.figure--portrait,
  &.figure--square {
    width: calc(100% - (@margin * 4));
    height: calc(100% - (@margin * 2));
    margin: @margin * 2;
    
    @media @desktop {
      margin: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
    }
  }
  [data-template="home"] & {
    width: 100%;
    height: 100%;
    margin: 0;
    background-size: cover;
  }
  @media @desktop {
    width: 100%;
    height: 100%;
    background-size: cover;
    margin: 0;
  }
}

/* ============================= 

          COLORS

================================ */


.bg--yellow {
  background-color: @theme_color;
}


/* ============================= 

          TYPOGRAPHY

================================ */

body {
  -webkit-font-feature-settings: "kern" off,"liga" off,"rlig" off,"calt" off,"case" on;
  -moz-font-feature-settings: "kern" off,"liga" off,"rlig" off,"calt" off,"case" on;
  -ms-font-feature-settings: "kern" off,"liga" off,"rlig" off,"calt" off,"case" on;
  font-feature-settings: "kern" off,"liga" off,"rlig" off,"calt" off,"case" on;
}

@mobile_font_size: 20px;

.h1 {
  font-size: 51px;
  line-height: 1;
  letter-spacing: -.1rem;
  @media @desktop {
    font-size: 15.1367vw;
    line-height: 1;
    letter-spacing: -.3rem;
  }
  text-align: center;
  .font();
  //.no-select();
}

.h1-title {
  text-align: center;
  .font();
  //.no-select();
  
  @media @desktop {
    font-size: 6.542vw;
    line-height: 1;
    letter-spacing: -.05rem;
  }
}

.h2 {
  font-size: 22px;
  line-height: 1;
  letter-spacing: .0rem;
  font-size: @mobile_font_size;
  line-height: 1;
  .font();
  //.no-select();
  @media @xs {
    font-size: 19.25px;
    font-size: @mobile_font_size;
  line-height: 1;
  }
  @media @desktop {
    font-size: 4.78vw;
    letter-spacing: -.05rem;
    letter-spacing: -.01rem;
  }
}

.h2-rte {
  font-size: @mobile_font_size;
  line-height: 1;
  .font();
  //.no-select();
  @media @desktop {
    font-size: 4.78vw;
    letter-spacing: -.05rem;
  }
}

.h3 {
  font-size: 17px;
  line-height: 1;
  @media @desktop {
    font-size: 2.929vw;
    line-height: 1;
    letter-spacing: 0rem;
    .font-text();
  }
}

.p {
  font-size: 17px;
  line-height: 1;
  .font-text();
  @media @desktop {
    font-size: 2.929vw;
    line-height: 1;
    letter-spacing: -.05rem;
    .font();
  }
  
}

.p-smaller {
  font-size: 12px;
  line-height: 12.5px;
  .font-text();
  @media @desktop {
    font-size: 1.6vw;
    line-height: 1.12;
    letter-spacing: -.01rem;
    .font();
  }
  p {
    font-size: 12px;
  line-height: 12.5px;
    .font-text();
    @media @desktop {
      font-size: 1.6vw;
      line-height: 1.12;
      letter-spacing: -.01rem;
      .font();
    }
    
  }
}

.small {
  font-size: 12px;
  line-height: 12.5px;
  .font-text();
  letter-spacing: 0.02rem;
}

.caption {
  font-size: 12px;
  line-height: 12.5px;
  .font-text();
  @media @desktop {
    font-size: 1vw;
    line-height: 1.12;
    letter-spacing: -.0rem;
    .font();
  }
}

.p-rte {
  font-size: 17px;
  line-height: 1;
  
  .font();
  //.no-select();
  
  @media @desktop {
    font-size: 1.6vw;
    line-height: 1.12;
    letter-spacing: -.01rem;
  }
}

h1 { .h1(); }
h2, h3, h4 { .h2(); }
p { .p(); }

.no-wrap {
  white-space: nowrap;
}

[data-barba-namespace="project"] .banner--title.fade-in-out {
  -webkit-animation: fadeInOut 2s;
  animation: fadeInOut 2s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
  opacity: 0;
  pointer-events: none;
}

hr {
  padding-top: 1em;
  border: none;
  background: none;
}

.banner--title {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: @theme_color;
  padding: .69rem;
  white-space: nowrap;
  margin: 0;
  .h2();

  [data-barba-namespace="project"] & {
    padding: .69rem;
    opacity: 0;
    z-index: 3;
    position: fixed;
    pointer-events: none;
    @media @desktop {
      padding: 2rem 2.2rem;
    }
  }
  
  @media @desktop {
    padding: 2rem 2.2rem;
  }
  [data-barba-namespace="project"] & {
    opacity: 0;
  }
  [data-barba-namespace="home"] & {
    left: 0;
    top: 0;
    transform: none;
    padding: 0;
    background: none;
    .margins();
    display: none;
    
    .top-position();
    a {
      .gray();
      &:hover {
        color: #000;
        .gray-hover();
      }
    }
  }
}

.comma {
  display: none;
  @media @desktop {
    display: inline-block;
  }
}

.icon--close {
  width: 2.5vw;
  height: 2.5vw;
  display: block;
  svg {
    width: 100%;
    height: auto;
    line {
      [data-text-color="white"] & {
        stroke: #fff;
      }
    }
    
  }
}

a, button {
  transition: @transition_speed_link color;
  transition-property: color, border;
  
}


@import 'form.less';

/* ============================= 

          VIDEO

================================ */


.plyr__control--overlaid {
  background-color: rgba(255, 255, 255, .8);
}
.plyr--video .plyr__control.plyr__tab-focus, .plyr--video .plyr__control:hover, .plyr--video .plyr__control[aria-expanded=true] {
  background-color: rgba(255, 255, 255, 1);
}
.plyr__control svg {
  mix-blend-mode: difference;
}
.plyr--full-ui input[type=range] {
  color: #fff;
}

video { outline: none; display: block; }

.video-embed {
/* width is set as 100% here. any width can be specified as per requirement */
width: 100%;
padding-top: 56.25%;
height: 0px;
position: relative;
  video {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
  }
}

.aspect-ratio--16x9 {
  object-fit: cover;
}
.aspect-ratio__inner-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  object-fit: cover;
  background-size: cover;
  background-position: center;
  video {
    font-family: 'object-fit: cover; object-position: center center;';
    object-fit: cover;
  }
  
}

/* ============================= 

          RTE

================================ */


#Hub {
  /*
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
  -webkit-transform: translate3d(0,0,0);
  -webkit-transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000;
  transform: translate3d(0,0,0);
  transform: translateZ(0);
  */
  transition: 0s opacity;
  //will-change: opacity, scroll-position;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;

  [data-text-color="white"] & {
    color: #fff;
    a, button {
      .inverse-link();
    }
    .filter--title {
      color: #000 !important;
    }
    form {
      input {
        .input-inverse();
      }
    }
    svg {
      fill: #fff;
      path {
        fill: #fff !important;
      }
    }
  }

  .reveal-menu & {
    //opacity: 1;
    visibility: visible;
    //pointer-events: all;
    
    transition: @transition_speed_content_in opacity;
    transition-delay: 1.05s;
  }

  .show-menu & {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
    
    //background: rgba(0,0,0,0.8);
    
    
    transition: @transition_speed_content_in opacity;
    transition-delay: 0s;
    //transition: none;
  }

  header {

    //position: sticky;
    top: 1rem;

    
    h1, .filter {
      display: inline-block;
    }
    .filter {
      //display: none;
      display: inline-block;
      @media @desktop {
        display: inline-block;
      }
      
      &.active, &:hover:not(.filter--single) {
        li {
          display: inline-block;
          &:first-child {
            display: none;
          }
        }
      }
      &.filter--single {
        li {
          display: inline-block;
        }
      }
      li {
        display: none;
        margin-right: 1rem;
        @media @desktop {
          margin-right: 4rem;
        }
        &:first-child {
          display: inline-block;
        }
      }
    }
  }
  .rte {
    .h2();
    
    padding-top: 2.5rem;

    &[data-template="default"],
    &[data-template="people"],
    &[data-template="person"],
    &[data-template="studios"] {
      .h2-rte();
      p, div, span, h2 {
        .h2-rte();
      }
    }

    @media @desktop {
      padding-top: 1.75em;
    }

    .filter--title {
      .gray();
    }

    header {
      margin-bottom: 1em;
      h1 {
        display: none;
        margin-right: 4rem;
      }
      &.section-header {
        margin: 0;
        padding-top: 2em;

        + .rte-section {
          margin-bottom: 0;
        }
      }
    }
    
    h1 {
      .h2();
      margin: 0;
      text-align: left;
      
    }

    p, div, span {
      .h2();
      margin-bottom: 1em;
      &:last-child, &:last-of-type {
        margin-bottom: 0;
      }
    }
    .type--small {
      .p-rte();
      p {
        .p-rte();
      }
      @media @desktop {
        font-size: 1.6vw;
        line-height: 1.12;
        letter-spacing: -.01rem;
      }
      
    }
    li {
      list-style: none;
    }
    ul:not(.filter) {
      margin-bottom: 1em;
      &:last-of-type {
        margin-bottom: 0;
      }
    }

    a:hover, a.active:hover {
      color: #fff;
      opacity: 1;
    }

    /* =============== PEOPLE ================= */
    .search-terms {
      margin: @desktop_margin 0;
      clear: both;
      li {
        .p-rte();
        display: block;
        float: left;
        .comma {
          .p-rte();
          display: inline-block;
        }
        &:last-of-type {
          .comma { display: none; }
        }
      }
      &:after {
        content:'';
        clear: both;
        display: block;
      }
    }

  }
}

.rte {
  p + p {
    margin-top: 1em;
  }
  &.type--small {
    
    button {
      .p-rte();
      cursor: pointer;
      align-self: flex-start; 
    }
  }
}


&[data-template="search"] {
  .flex-display();
  .flex-direction(column);
  form { order: 1; }
  .search-results { 
    order: 2; 
    margin-top: @desktop_margin;
    @media @desktop {
      margin-top: 0;
    }
  }
  .search-terms { 
    order: 3; 
  }
  @media @desktop {
    display: block;
  }
  .searchbox {
      position: relative;
      
      button[type="submit"] {
        display: none;
      }
    
  }
}

/* ============================= 

          BUTTONS

================================ */

button {
  //.p();
  border: none;
  outline: none;
  //margin-top: 5rem;
  cursor: pointer;
  background: none;
  .h2-rte();

}

a, .a {
  text-decoration: none;
  color: inherit;
  outline: none;
  &.active, &:not(.css-ignore):hover {
    .gray();
  }
}

.link--secondary {
  .gray();
  &:hover {
    .gray-hover();
  }
}

/* ============================= 

          FORM

================================ */


input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  //border: none;
  -webkit-text-fill-color: black;
  -webkit-box-shadow: 0 0 0px 1000px rgba(1, 1, 1, 0) inset;
  transition: background-color 5000s ease-in-out 0s;
}

/* clears the 'X' from Internet Explorer */
input.hide-clear[type=search]::-ms-clear,
input.hide-clear[type=search]::-ms-reveal {
  display: none;
  width: 0;
  height: 0; 
}

[type=search] {
  -webkit-appearance: initial;
}

/* clears the 'X' from Chrome */
input.hide-clear[type="search"]::-webkit-search-decoration,
input.hide-clear[type="search"]::-webkit-search-cancel-button,
input.hide-clear[type="search"]::-webkit-search-results-button,
input.hide-clear[type="search"]::-webkit-search-results-decoration {
  display: none; 
}

form {
  width: 100%;
  top: 0rem;
  position: relative;
  @media @desktop {
    top: -.3rem;
  }
  input {
    margin: 0;
    background: none;
    outline: none;
    border: none;
    width: 100%;
    border-bottom: 1px solid black;
    border-radius: 0;
    //.p();
    @media @desktop {
      border-bottom: 2px solid black;
      font-size: inherit;
      line-height: inherit;
    }
    &::placeholder {
      .placeholder();
    }
  }
  a.reset, button.reset {
      position: absolute;
      right: 0;
      bottom: .28em;
      opacity: 0;
      pointer-events: none;
      padding-right: 0;
      transition: @transition_speed_link all;
      width: .55em;
  }
  a.reset.visible {
    pointer-events: auto;
      opacity: 1;
  }
  div {
    position: relative;
    width: 100%;
    margin: 0 !important;
  }
  button {
    
    .a();
    //padding-top: 1em;
    
    &[type="submit"] {
      .h2-rte();
      .rounded();
      color: #000;
      position: relative;
      top: 0;
      display: inline-block;
      float: left;
      border: 1px solid @active_text;
      color: @active_text;
      //border-radius: @rounded_unit;
      padding: @select_padding;
      margin-right: 1rem;
      margin-top: 1em;
      margin-bottom: 4em;
      @media @desktop {
          border-width: 2px;
      }
      &:hover {
          border-color: #000;
      }

      .search & {
        position: absolute;
        right: 0;
        bottom: .2rem;
        margin-top: 0;
        margin-bottom: 0;
      }
    }
    .gray();
    &:hover {
      color: #000 !important;
      .gray-hover();
      opacity: 1 !important;
    }
  }
}

.form-signup {
  .a();
}

.newsletter {
  
  .form-input {
    display: none;
  }
  &.active {
    .form-signup {
      display: none;
    }
    .form-input {
      display: block;
    }
  }
}

/* ============================= 

          LAYOUT

================================ */
body {
  //transition: 1s width;
}

.container {
  .margins();
  position: relative;
}


.main-feature[data-template="home"] {
  height: 100%;
  & > section { height: 100%; }
}

.type {
  fill: #888;
  text-anchor: middle;
}

.module--svg-text {
  transform-origin: top left;
  opacity: 0;
  //margin: .9rem 1.25rem;

 //width: calc(100% - 2.5rem);
  //height: calc(100% - 1.8rem);
  .resizing & {
    .fade-out();
    animation-delay: 0s;
    animation-duration: .15s;
  }
  tspan, text {
    //.p-smaller();
    letter-spacing: -.005rem;
  }
}

.shape {
  fill: transparent;
  //fill: #eee;
  //stroke: #ccc;
  //width: 100%;
  //height: 100%;
  
}

/* ============================= 

          MENU

================================ */


#Logo {
  margin: 0 @margin;
  position: absolute;
  width: calc(100% - (@margin * 2));
  transition-duration: @transition_speed_content_in;
  transition-property: top;
  will-change: top;
  //transition-delay: @transition_speed_content_in * 2;
  display: block;
  bottom: 0;
  
  @media @desktop {
    padding: 0 30px 0px 0px 0;
    margin: 0 @desktop_margin;
    width: calc(100% - (@desktop_margin * 2));
    
  }
  &.delay {
    //transition-delay: @transition_speed_content_in * 2;
  }
  &:hover {
    opacity: 1 !important;
  }
  svg {
    //margin-bottom: @margin;
    padding-bottom: @margin;
    //height: 100%;
    //width: auto;
    @media @desktop {
      
      padding-bottom: @desktop_margin;
    }
  }
}

#LogoDummy {
  //.margins();
  width: 100% !important;
  @media @desktop {
    
  }
  display: block;

}

#Hub {
  position: fixed;
  max-height: 100%;
  height: 100%;
  width: 100%;
  z-index: 99;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  perspective: 1000;
  will-change: transform;
  top: 0;
  left: 0;
  
  &:before {
    
    display: block;
    position: fixed;
    width: 100%;
    height: 50vw;
    top: 0;
    pointer-events: none;
    background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.9037990196078431) 49%, rgba(255,255,255,0) 100%);
    opacity: .95;
  }

  .scroll-container {
    //max-height: 100vh;
    height: 100%;
    width: 100vw;
    overflow-y: scroll;
    overflow-x: hidden;
    top: 0;
    display: block;
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
    will-change: transform;
    //scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    .hide-scrollbars();

    &:before {
      content: "";
      display: block;
      width: 100%;
      height: 100.065% !important;
      position: absolute;
      pointer-events: none;
      left: 0;
      top: 0; 
    }
    
    &:after {
      content:'';
      display: block;
      width: 100%;
      height: @margin;
      @media @desktop {
        height: @desktop_margin;
      }
    }
    & > div:not(#Menu) {
      display: inline-block;
      width: 100%;
    }

    .rte {
      main {
        //-webkit-mask-image: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.9037990196078431) 19%, rgba(255,255,255,0) 100%);
      }
    }
  }
}

nav {
  height: 100vh;
  position: relative;

  //mix-blend-mode: multiply;
  
  .show-menu & {
    .flex-display();
    opacity: 1;
    @media @desktop {
      .flex-display();
      opacity: 1;
    }
  }
  #Menu {
    margin-top: @mobile_header_height;
    position: relative;
    z-index: 1;
    display: inline-block;
    padding-bottom: 0;
    @media @desktop {
      .flex-display();
      margin-top: 0;
    }
    @media @mobile_landscape {
      .flex-display();
      margin-top: @mobile_header_height;
    }
    ul {
      margin-right: 0rem;
      @media @desktop {
        margin-right: 4rem;
      }
      @media @mobile_landscape {
        margin-right: 2rem;
      }
      li {
        list-style: none;
        a {
          .h2();
          &:hover, &.active:hover {
            color: #fff;
            opacity: 1;
            
          }
        }
      }
    }
  }
  
}

#Menu, #HubContainer {
  .margins();
}

#HubContainer {
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
  .rte {
    //opacity: 0;
    padding-bottom: 2em;
   

    &.fade-in {
      animation: @transition_speed_link*2 fadeIn;
    }
    .fade-in-rte {
      //animation: @transition_speed_content_in fadeIn;
    }
    //transition: @transition_speed_link opacity;
    
    &.init {
      opacity: 1;
      
      //transition-delay: 1s;
    }
    &.remove {
      opacity: 0;
      position: absolute;
      width: 100%;
      width: calc(100% - @margin * 2);
      @media @desktop {
        width: calc(100% - @desktop_margin * 2);
      }
      &.fade-out-rte {
        animation: @transition_speed_link*2 fadeOutRTE;
      }
    }
    //display: inline-block;
  }

  .rte-lead-in {
    padding-bottom: 2.5rem;
    @media @desktop {
      padding-bottom: 1.75em;
    }
  }
 
}



#ToTop, .ToTop {
  margin-top: 2em;
  text-align: left;
  //display: none;
  .h2();
  color: #000;
  &:hover {
    color: #fff;
  }

  [data-text-color="white"] & {
    .inverse-link();
  }
  @media @desktop {
    display: inline-block;
  }
}



/* ============================= 

          MOBILE MENU

================================ */


#MobileMenu {
  background: #F7F7F7;
  height: 37px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  @media @desktop {
    display: none;
  }
  #Hamburger {
    width: 40px;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 1rem;
    margin-top: 0;
    height: 100%;
    line-height: 1;
    background: none;
    span {
      width: 20px;
      height: 2px;
      background-color: #000;
      display: block;
      margin-top: 4px;
      margin-left: 10px;
      &:first-child {
        margin-top: 0px;
        
      }
    }
  }
}

/* ============================= 

          MENU FOOTER

================================ */


.menu--footer-logo {
  width: 100%;
  display: block;
  position: fixed;
  bottom: 0;
  //padding-bottom: .1rem;
  z-index: 4;
  display: none;
  left: @margin;
  height: auto;
  @media @desktop {
    height: .85em;
  }
  [data-template="home"] & {
    .fade-out();
    pointer-events: none;
  }
  .show-menu & {
    z-index: 3;
    .filter-blur();
  }
  [data-text-color="white"] & {
    @media @desktop {
      fill: #fff;
      g {
        fill: #fff;
      }
    }
  }
  @media @desktop {
    width: 14.249%;
    bottom: .1em;
    display: block;
    margin-bottom: @desktop_margin * .65;
    left: @desktop_margin;
  }
  img {
    width: 100%;
  }
}

.menu--footer {
  .no-select();
  position: fixed;
  z-index: 2;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: left;
  opacity: 0;
  //mix-blend-mode: multiply;
  [data-text-color="white"] & {
    @media @desktop {
      mix-blend-mode: unset;
      opacity: 1;
      ul {
        opacity: 1;
        li:not(.active) {
          //mix-blend-mode: soft-light;
          a:hover {
            mix-blend-mode: unset;
            color: #fff;
            //opacity: .4;
          }
        }
        li.active {
          a {
            color: #fff;
            
            //opacity: .4;
          }
        }
      }
    }
    
  }

  &.menu--footer-home {
    //fade in
    opacity: 0;
    .fade-in();
    animation-delay: 2s;
    @media @desktop {
      //display: none;
      animation-delay: 0s;
      pointer-events: none;
    }
    ul {
      li:first-child {
        display: block;
      }
    }
  }
  
  

  .menu--footer-nav {
    display: inline-block;
    margin: 0 auto;
    
    @media @desktop {
      margin-left: 20vw;
    }
    [data-template="project"] & {
      //opacity: .2;
    }
    [data-barba-namespace="program"] & {
      opacity: 0;
      transition: 1s opacity;
      transition-delay: 1s;
    }
    [data-barba-namespace="program"].visible & {
      opacity: 1;
    }
    &:hover, &:active {
      opacity: 1;
      li {
        display: block;
      }
    }
    ul {
      &.menu--footer-nav-default {
        li:first-child {
          display: block;
        }
      }
    }
    li {
      text-align: left;
      display: none;
      &.active {
        display: block;
        a {
          color: #000;
          opacity: 1;
          [data-text-color="white"] & {
            @media @desktop {
              color: #fff;
            }
            
          }
          @media @desktop {
            //.gray();
          }
          
        }
      }
      a {
        .h2();
        &:hover {
          .gray();
        }
      }
    }
  }
}

/* ============================= 

          HOME

================================ */


[data-barba-namespace="home"] {
  //height: 100vh;
  overflow-y: hidden;
  
  .slick { height: 100% !important; }
}

[data-template="home"] {
  .menu--footer-logo {
    display: block;
    margin-bottom: @margin;
    width: calc(100% - (@margin * 2));
    @media @desktop {
      display: none;
    }
  }
}


/* ============================= 

          PROGRAME

================================ */


[data-template="program"] {
  &:after {
    content:'';
    display: block;
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    z-index: 1;
    pointer-events: none;
    height: @mobile_header_height * 2;
    pointer-events: none;
    //margin-left: -@desktop_margin;
    //margin-right: -@desktop_margin;
    background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.98) 78%, rgba(255,255,255,0) 100%);
    opacity: .99;
    .blur-section-only &, .show-menu & {
      display: none;
    }
    @media @desktop {
      height: 7.5vw;
      background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.94) 75%, rgba(255,255,255,0) 100%);
    }
  }
  //background: rgb(255,255,255);
  //background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.98) 15%, rgba(255,255,255,0) 100%);
}

.program--counter {
  .margins();
  .h2();
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 2;
  opacity: 0;
  transition: 1s opacity;
  transition-delay: 1s;
  will-change: opacity; 
}

[data-barba-namespace="program"].loaded {
  transition: 1s opacity;
}

[data-barba-namespace="program"].visible {
  
  .program--title-screen {
    opacity: 0;
    transition-delay: .85s;
  }
  .program--counter {
    opacity: 1;
    
  }
  .program--title-screen {
    pointer-events: none;
  }
  .program-blocks {
    //opacity: 1;
    
    .program--row-question {
      margin-left: 0;
      margin-right: 0;
      .flex-display();
      width: 100%;
      align-items: center;
      .flex-direction(column);

      &:first-child {
        .program--module-question:first-child + .program--module {
          margin-top: 50px;
          @media @desktop {
            margin-top: 3.90625vw;
          }
        }
      }
    }
    .program--module {
      opacity: 1;
    }
  }
}

.program--title-screen {
  height: 100%;
  .flex-display();
  justify-content: center;
  align-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  //pointer-events: none;
  transition: 1s opacity;
  transition-delay: 1.2s;
  will-change: opacity; 
  h1 {
    padding-top: @mobile_header_height;
    @media @desktop {
      padding-top: 0;
    }
  }
  
}



.program-list {
  ul {
    .list-li {
      display: block;
    }
  }
  li {
    display: block;
  }
}

.module--four-three {
  //background-color: red;
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 75%;
  }
  & > div.rte, & > a.rte {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.program-blocks {
  display: block;
  flex-wrap: wrap;
  justify-content: center;
  
  margin-left: @margin + 10;
  margin-right: @margin + 10;

  //padding-top: @mobile_header_height;
  padding-bottom: 100px;
  
  @media @desktop {
    padding-top: 0;
    margin-left: @desktop_margin;
    margin-right: @desktop_margin;
    margin-bottom: @desktop_margin;
  }
  .program--module {
    opacity: .001;
    //will-change: opacity; 
    transition: 1s opacity;
    
    &:not(.program--module-question) {
      @media @xxl {
        //max-width: 1200px;
      }
    }
    
    &.program--module-question {
      transition-delay: 1s;
      pointer-events: none;
    }
  }
  .program--row {
    .flex-display();
    justify-content: space-between;
    align-content: center;
    align-items: center;
    width: 100%;
    .flex-direction(column);
    //max-width: 1600px;
    @media @desktop {
      flex-direction: row;
      &:before, &::after {
        content:'';
        display: block;
      }
    }
    .program--module:last-child {
      margin-bottom: 100px;
      @media @desktop {
        margin-bottom: 7.8125vw;
      }
    }
    
  }
  @media @desktop {
    margin-left: 0;
    margin-right: 0;
    .flex-display();
  }
  .program--module {
    margin-bottom: 100px;
    @media @desktop {
      margin-bottom: 7.8125vw;
    }
    &.program--module-question {
      margin-bottom: 0;
    }
    &.program--module-question + .program--module,
    &.program--module-question + .program--row {
      margin-top: 100px;
      @media @desktop {
        margin-top: 7.8125vw;
      }
    }
    &:last-child {
      margin-bottom: 100px;
      @media @desktop {
        margin-bottom: 7.8125vw;
      }
    }
    
  }
}

.module--large-image {
  width: 100%;
  position: relative;
  &:not(.module--square) {
    .program--module-image-text {
      .rte {
        @media @desktop {
        
        position: absolute;
        width: 66.7%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        }
      }
    }
  }
  .slick-slide > div {
    //.flex-display();
  }
 
  @media @desktop {
    max-width: 66%;
  }
  &.module--square {
    @media @desktop {
      max-width: 50%;
    }
  }
  figure {
    width: 100%;
    margin: 0 auto;

    @media @desktop {
      //width: 66%;
    }
  }
  .rte-container {
    .p-smaller();
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    height: 100%;
    .flex-display();
    .flex-direction(column);
    justify-content: space-between;
    width: 100%;
    
    .program--module-image-text,
    .program--module-image-text-header {
      //.margins();
      margin: 1.25rem;
    }
  }
}

//square
.module--large-image.module--square {
  @media @desktop {
    max-width: 40%;
  }
}

.program--module-introduction {
  //width: 100%;
  .margins();
  margin-left: -10px;
  margin-right: -10px;
  position: relative;
  margin-top: 20px;
  //margin-bottom: 0 !important;
  padding-bottom: 0px !important;
  display: block;
  margin-bottom: 100px !important;
  padding-top: @mobile_header_height/2-1 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  
  @media @desktop {
    margin-top: 0;
    margin-bottom: 3.90625vw !important;
    width: 100%;
  }
  h2 {
    
  }
  h2, .h2 {
    .margins();
    padding-bottom: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    p {
      .h2();
    }
    a:hover {
      color: #000;
    }
    @media @desktop {
      margin-left: @desktop_margin;
      margin-right: @desktop_margin;
    }
  }
}

.program--module-question {
  width: 100%;
  position: relative;
  margin-left: -0px;
  margin-right: -0px;
  z-index: 1;
  position: sticky;
  padding-top: @mobile_header_height/2-1 !important;
  top: 0px;
  left: 0px !important;

  @media @desktop {
    margin: 0;
    padding-top: 0px !important;
  }
  @media @desktop {
    
  }
  
  .program--module-question-inner-container {
    margin-left: -10px;
    margin-right: -10px;
    position: relative;

    @media @desktop {
      margin-left: @desktop_margin;
      margin-right: @desktop_margin;
    }
    
    
  }
  
  &.js-is-stuck .program--module-question-inner-container:after,
  &.js-is-sticky .program--module-question-inner-container:after {

      content:'';
      display: block;
      position: absolute;
      overflow-x: hidden;
      width: 100%;
      left: 0;
      top: -1px;
      z-index: -1;
      transform: rotate(180deg);
      height: 7.5vw;
      opacity: .99;
      background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.92) 75%, rgba(255,255,255,0) 100%);
      @media @desktop {
        top: 0;
      }
    
  }
  
  h2 {
    position: absolute;
    top: 0;
    left: 0;
    .margins();
    padding-bottom: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  span.h2 {
    .margins();
    margin-top: 20px;
    padding-bottom: 0px !important;
    display: block;
    @media @desktop {
      margin-top: 0;
      padding-bottom: 0 !important;
    }
  }
  .dummy-title {
    .h2();
  }
}

.module--caption {
  padding-top: 0.35em;
  opacity: 0;
  transition: @transition_speed_content_in opacity;
  position: absolute;
  .caption();
  width: 100%;
  [data-studio] & {
    opacity: 1;
  }
  .module--caption-title,
  .module--caption-subtitle {
    display: block;
  }
  p {
    .caption();
    //display: inline-block;
    margin-right: .6rem;
    //text-overflow: ellipsis;
  }
  .in-view & {
    opacity: 1;
  }
  &:hover, a:hover {
    color: #000 !important;
  }
}

.module--video {
  width: 100%;

  @media @desktop {
    width: 66%;
  }
  [data-template="home"] & {
    width: 100%;
    height: 100vh;
  }
  overflow: hidden;
  //padding-top: 49%;
  position: relative;
  margin: 0 auto;
  z-index: 0;
  iframe {
    border: 0;
   height: 100%;
   left: 0;
   position: absolute;
   top: 0;
   width: 100%;
  }

  video {
    height: auto;
    width: 100%;
  }

  .module--caption {
    position: relative;
  }
}

.module--project {
  position: relative;
  width: 100%;
  @media @desktop {
    width: 66%;
  }

  &.module--four-three {
    .slick {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      .slick-slide {
        text-align: center;
      }
      .project-slick-holder {
        height: 100%;
        width: 100%;
      }
      .figure--bg {
        background-size: cover;
      }
      .figure--contain {
        &.figure--landscape {
          //background-size: 66%;
          background-size: cover;
        }
        &.figure--portrait,
        &.figure--square {
          //background-size: 100%;
          background-size: contain;
        }
        
        background-color: @color_gray_bg;
      }
    }
    figure, img {
      height: 100%;
      width: 100%;
      //display: inline-block;
      margin: 0 auto;
    }
  }
}

.module--text {
  position: relative;
  //.p-smaller();
  width: 100%;
  //overflow: hidden;

  figure {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: none;
    height: 100%;
    width: 100%;
    img {
      height: 100%;
      width: auto;
    }
  }
  .module--text-container {
    .flex-display();
    .flex-direction(column);
    width: calc(100% - 2.5rem);
    height: calc(100% - 2rem);
    *:nth-last-child(2) {
      flex-grow: 1;
    }
  }
  &.module--text-has-image {
    &:hover {
      figure {
        display: block;
        position: relative;
        //z-index: 1;
      }
      .module--text-container {
        visibility: hidden;
      }
    }
  }
  

  @media @desktop {
    width: 44%;
  }
  .rte {
    //.flex-display();
    justify-content: space-between;
    .flex-direction(column);

    

    & > div, & > a { 
      margin: 1rem 1.25rem;
      //padding:15px;
      @media @desktop {
        //padding: @desktop_margin;
      }
      //.margins();
      + div {
        padding-top: 0;
      }
    }
    
    h4, h5, p {
      .p-smaller();
    }
  }
  a {
    //.margins();
    //display: block;
  }
  h4 {
    //margin-top: 4rem;
  }
  
}

.slick-slide > div {
  .flex-display();
}

/*
.module--portrait--medium {
  .slick {
    @media @desktop {
      margin: @margin * 4;
      //margin-bottom: -(@margin * 4);
    }
  }

  .module--caption {
    @media @desktop {
      margin: @margin * 4;
      margin-top: -(@margin * 4);
    }
  }

}

.module--portrait--small {
  .slick {
    @media @desktop {
      margin: @margin * 7;
      //margin-bottom: 0;
    }
  }
  .module--caption {
    @media @desktop {
      margin: @margin * 7;
      margin-top: -(@margin * 7);
    }
  }
}

.module--portrait,
.module--square {
  figure, .slick {
    margin: 0 25px;
    @media @desktop {
      margin: 0 50px;
    }
    
  }
  .slick {
    figure {
      margin: 0;
    }
  }
}
@media @desktop {
  &.module--portrait,
  &.module--square {
    figure, .slick {
      margin: 0;
    }
    .slick {
      figure {
        margin: 0;
      }
    }
  }
}
*/
  

.module--image {
  width: 100%;

  .module--image-inner {
    position: relative;
  }
  
  .slick-slide > div {
    //.flex-display();
  }

  @media @desktop {
    width: 44%;
  }
  .reflection-blocks & {
    @media @desktop {
      width: @max_reflection_module_width_desktop;
    }
  }

  .slick--figure-container {
    text-align: center;
    figure {
      width: auto;
      margin: 0 auto;
      display: inline-block;
      &[data-orientation="landscape"] {
        height: auto;
        width: 100%;
        img {
          height: auto;
          width: 100%;
          opacity: 0;
          transition: @transition_speed_content_in opacity;
          &.lazyloaded {
            opacity: 1;
          }
        }
      }
      img {
        

        @media @desktop {
          height: 100%;
          width: auto;
          //max-height: calc(75vw * .75);
          height: calc(75vw * .75);
        }
      }
      figcaption {
        text-align: left;
      }
    }
  }

  .program--module-image-text {
    
    .rte {
      padding: 1rem 1.25rem;
      width: calc(100% - 2.5rem);
      height: calc(100% - 2rem);
      
    }
  }

  //Square
  //–––Large
  &.module--square--large {
    @media @desktop {
      width: 49.556%;
    }
  }

  //Portrait
  //–––Medium
  &.module--portrait--medium {
    .slick {
      margin: 0;
    }
    .module--image-inner {
      margin: 0 12.5%;
    }
  } 
  //–––Large
  &.module--portrait--large {
    @media @desktop {
      width: 37.153%;
    }
  } 

  //Landscape
  //–––Small
  &.module--landscape--small {
    @media @desktop {
      width: 35.25%;
    }
    .module--image-inner {
      //margin: 0 10%;
    }
  }
  //–––Medium
  &.module--landscape--medium {
    @media @desktop {
      .slick {
        //padding: 0 10%;
      }
    }
  }
  //–––Large
  &.module--landscape--large {
    @media @desktop {
      width: @max_module_width_desktop;
      
      .program--module-image-text {
        .rte-container {
          .p-smaller();
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
          height: 100%;
          .flex-display();
          .flex-direction(column);
          justify-content: space-between;
          width: 100%;
          .program--module-image-text,
          .program--module-image-text-header {
            //.margins();
            margin: 1.25rem;
          }
        }
        
        .rte {
          &:before {
            content:"";
          }

          .flex-display();
          justify-content: center;
          position: absolute;
          width: 66.7%;
          left: 50%;
          top: 50%;
          height: auto;
          //display: block;
          transform: translate(-50%, -50%);
          
        }
      }
    }
  }

  .rte {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    .p-smaller();
    height: calc(100% - 1rem) !important;
    width: 100%;
    padding-bottom: 0 !important;
    justify-content: space-between;
    overflow-y: auto;
    -ms-overflow-style: none;
    &::-webkit-scrollbar { display: none;  }
    box-sizing: content-box !important; /* So the width will be 100% + 17px */
    ul, ol { list-style-position: inside; margin-bottom: 1em; }
    .flex-display();
    .flex-direction(column);
    *:nth-last-child(2) {
      flex-grow: 1;
      //@-moz-document url-prefix() { padding-bottom: 4rem; }
    }
    
    *:last-child {
      padding-bottom: 1.25rem;
      /*
      z-index: 2;
      position: sticky;
      bottom: 0;
      left: 0;
      width: 100%;
      padding-bottom: 1.25rem;
      //padding-bottom: 1rem;
      &:before {
        content:'';
        z-index: -1 !important;
        .theme-gradient-up();
        height: 4rem !important;
        opacity: .95;
       
        //height: 2rem !important;
      }
      */
    }
    
    .program--module-image-text,
    .program--module-image-text-header {
      //.margins();
      margin: 1.25rem;
    }
  }
}

/* ============================= 

          PROJECTS

================================ */


.project--title {
  position: fixed;
  //.margins();
  .top-position();
  .h2();
  z-index: 1;
  display: none;
  width: 100% !important;
  text-align: left;
  &.fade-in, &.fade-in-normal {
    display: block;
  }
  [data-text-color="white"] & {
    color: #fff;
  }
  span {
    display: block;
    .margins();
    @media @desktop {
      width: 80%;
    }
  }
}

.list-li {
  a { 
    //display: block;
    .list-li-link();
    .li-label {
      float: right;
      &.year {
        width: 2.55em;
        position: absolute;
        right: 0;
        //padding-right: @margin/1.5;
        @media @desktop {
          //padding-right: @desktop_margin/1.5;
        }
      }
    }
  }
}

.slick-slider {
  user-select: auto;
  -webkit-touch-callout: inherit;
  -khtml-user-select: inherit;
  -ms-touch-action: inherit;
  touch-action: auto;
}

.slick-initialized .project--slide {
  opacity: 1;
}

.slick-initialized {
  .slick-slide {
    //opacity: 0;
    &.slick-active {
      opacity: 1;
    }
  }
}

.project--slide {
  //height: 100vh;
  height: 100%;
  background: #fff;
  opacity: 0;
  
  

  [data-switch-content] {
    width: 100%;
  }

  .project--video-container {
    height: calc(100% - 16vw);
    width: calc(100% - 17px*2);
    top: 7.5vw;
    left: 17px;
    display: flex;
    position: absolute;

    @media @desktop {
      height: calc(100% - 16vw);
    }

    video {
      height: auto;
      width: 100%;
      max-width: 100%;
      margin: auto;
      max-height: 100%;
      object-fit: contain;
      @media @desktop {
        height: 100%;
      }
      @media screen and (orientation:landscape) {
        width: auto;
      }
    }
  }

  @media @desktop {
   
    &.bg--yellow {
      background: @theme_color;
    }
  }
  &.project--slide-cover {
    h1 {
      position: absolute;
      .h1-title();
    }
    figure {
      height: 100%;
      width: 100%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
  }
  &.project--slide-text {
    background: @theme_color;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    .hide-scrollbars();

    &:before {

    }

    .rte {
      .flex-container-center();
      .margins();
      padding-top: 0;
      padding-bottom: 0;
      height: auto;
      min-height: 100%;
      .p-rte();

      &:before {
        content:'';
        .theme-gradient-down();
      }
      &:after {
        content:'';
        .theme-gradient-up();
      }

      [data-switch-content] {
        //margin: 60px 0;
        //padding: 60px 0;
        padding: 8rem 0 !important;
        height: auto;
        
      }

      .module--project & {
        .p-smaller();
      }
      ul:first-child {
        .module--project & {
          padding-top: 0;
        }
      }
      p:first-child {
        //padding-top: @mobile_header_height;
        .module--project & {
          padding-top: 0;
        }
        @media @desktop {
          padding-top: 0;
        }
      }
      @media @desktop {
        max-width: 66%;
        margin: 0 auto;
        padding-top: 0;
        padding-bottom: 0;
      }
      p, button, li, h6 {
        .p-rte();
        .module--project & {
          .p-smaller();
        }
      }

    }
  }
  &[data-size="small"] {
    //.flex-container-center();
    figure {
      background-size: contain;
      @media @desktop {
        height: calc(100% - 30vw);
        width: calc(100% - @margin*2);
        top: 15vw;
        left: @margin;
        background-size: contain;
      }
      position: relative;
    }
  }
  &[data-size="contain"] {
    //.flex-container-center();
    figure {
      background-size: contain;
      
      @media @desktop {
        height: calc(100% - 16vw);
        width: calc(100% - @margin*2);
        top: 7.5vw;
        left: @margin;
        background-size: contain;
      }
      position: relative;
    }

    video {
      pointer-events: all;
    }
  }
}

.slick--counter {
  .margins();
  .top-position();
  opacity: 1;
  position: fixed;
  [data-text-color="white"] & {
    color: #fff;
  }
}

[data-barba-namespace="project"] {
  .slick--counter {
    display: none;
    &.fade-in, &.fade-in-normal {
      display: block;
    }
  }
}

[data-credits] {
  background: @theme_color;
    .p-smaller();
    //.margins();
    .flex-container-center();
    padding-top: 0 !important;
    padding-bottom: 0;
    height: 100%;
    width: 100%;
    .flex-direction(column);
    @media @desktop {
      .flex-container-center();
      height: 100%;  
    }
    
    ul {
      align-self: center;
      width: 100%;
      padding-top: @mobile_header_height;
      @media @desktop {
        padding-top: 0;
      }
      
      li {
        list-style: none;
        position: relative;
        //.h3();
        
        margin-bottom: 1rem;
        @media @desktop {
          margin-bottom: 0;
        }
        h6 {
          position: relative;
          left: 0;
          top: 0;
          //.h3();
      
          @media @desktop {
            position: absolute;
            
          }
        }
        p {
          @media @desktop {
            margin-left: 30%;
          }
          &:first-child {
            padding-top: 0 !important;
          }
        }
        h6, p {
          @media @desktop {
            display: inline-block;
          }
        }
        .comma {
          &:last-child {
            display: none;
          }
        }
      }
    }
  
}

/* ============================= 

          PROJECT

================================ */
[data-template="project"] {
  .menu--footer {
    opacity: 0;
  }
}

.close-icon {
  position: fixed;
  z-index: 2;
  .margins();
  right: 15vw;
  top: 1.2vw;
  opacity: 0;
  display: none;
  &:hover {
    .gray();
    //mix-blend-mode: multiply;
    //mix-blend-mode: normal !important;
  }
  @media @desktop {
    display: block;
  }
}

[data-barba-namespace="project"] {
  overflow: hidden;
  height: 100vh;
  
  .slick {
    height: 100%;
  }
}

/* ============================= 

          PEOPLE

================================ */

.list-li {
  display: block;
  @media @desktop {
    display: inline-block;
  }
  span.no-wrap {
    [data-template="people"] &,
    [data-template="person"] & {
      text-overflow: unset;
      width: auto;
    }
  }
  [data-template="program"] &,
  [data-template="project"] & {
    display: block;
  }
  [data-template="program"] & {
    .no-wrap { width: 100%; }
    .li-label { display: none; }
  }
  &.active {
    .list--accordion {
      display: block;
    }
    .comma {
      //display: none;
    }
  }
  &:nth-last-child(2) {
    .comma {
      //display: none;
    }
  }
  &:last-child {
    .comma {
      display: none;
    }
  }
}
.list--accordion {
  //margin: .5rem 0 2rem 0;
  display: none;
  width: 100%;
  @media @desktop {
    position: absolute;
    left: 0;
    
  }
  
  .list--accordion-inner {
    @media @desktop {
      max-width: 66%;
      margin: 0 auto;
      padding: 3rem;
    }
  }
  p {
    //overflow: hidden;
    margin: 1rem 0 2rem 0 !important;
    //-webkit-animation: slideDown 1s;
    //animation: slideDown 1s;
    //animation-timing-function: linear;
    @media @desktop {
      margin: 0;
    }
  }
  
}


/* ============================= 

          PERSON

================================ */

[data-template="person"].rte {
  transition: none !important;
}


.search-results {
  display: block;
  clear: both;
  margin-top: @desktop_margin !important;
  li {
    display: block;
    .search-result-label {
      text-indent: @margin;
      @media @desktop {
        float: right;
      }
      
    }
  }
}


/* ============================= 

          REFLECTION

================================ */

.reflection-list {
  .list-li {
    width: 100%;
  }
}

.article-caption {
  position: fixed;
  z-index: 4;
  top: 0;
  pointer-events: none;
  color:#000;
}
.article--prev, .article--next {
  position: absolute;
  //background: red;
  width: 18.5%;
  height: 100vh;
  top: 0;
  z-index: 1;
  display: none;

  @media @desktop {
    display: block;
  }
  
  a {
    display: block;
    width: 100%;
    height: 100%;
    text-indent: -9999px;
    font-size: 0;
    line-height: 0;
  }
}
.article--prev {
  left: 0;
  a {
    cursor: url(@left_arrow), pointer;
  }
}
.article--next {
  position: fixed;
  right: 0;
  a {
    cursor: url(@right_arrow), pointer;
  }
}
[data-barba-namespace="reflection"] {
  
  & > section:after {
    
  }
  .reflection--title {
    text-align: left;
    position: sticky;
    z-index: 2;
    left: 0;
    display: block;
    width: 82%;
    top: @mobile_header_height;
    
    @media @desktop {
      top: 0;
      margin: 0;
    }
    span {
      position: relative;
      z-index: 2;
    }
    &:after {
      content:'';
      display: block;
      position: absolute;
      width: 100%;
      left: 0;
      top: 0;
      //top: @mobile_header_height;
      z-index: 1;
      height: 100%;
      pointer-events: none;
      background: rgb(255,255,255);
      background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.98) 78%, rgba(255,255,255,0) 100%);
      @media @desktop {
        //height: 8.5vw;
        top: 0;
        background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.98) 75%, rgba(255,255,255,0) 100%);
      }
    }
  }
}
.module--wysiwyg {
  //.margins();
  .p-smaller();
  @media @desktop {
    max-width: @max_reflection_module_width_desktop;
    margin: 0 auto;
    padding-top: 0;
    padding-bottom: 0;
  }

  a {
    //text-decoration: underline;
    &:hover {
      color: #000;
      text-decoration: none;
    }
  }
}

.reflection-blocks {
  padding-bottom:  @mobile_header_height + @margin * 3;
  //padding-top: @mobile_header_height + @margin * 3;
  margin-left: @margin;
  margin-right: @margin;
  opacity: 0;
  @media @desktop {
    padding-bottom: 7.8125vw;
    //padding-top: calc(7.8125vw);
  }
  .program--module {
    margin: 0 auto;
    //margin-bottom: @margin;
    //opacity: 0;
    padding-top: @margin;
    @media @desktop {
      //padding-top: 0;
      width: @max_reflection_module_width_desktop;
      margin: 0 auto;
    }

    //module--image
    &.module--image {

      //caption 
      .module--caption {
        position: relative;
      }

      //slick
      .slick {
        .slick-slide {
          > div {
            .justify-content(center);
            
            @media @desktop {
              height: auto;
            }
          }
        }
        figure {
          background-size: contain;
          height: 100%;
          /*
          width: 100% !important;
          margin: 0 !important;
          */
          opacity: 1 !important;
          &.figure-portrait {
            height: 100%;
            //width: auto !important;
          }
          @media @desktop {
            height: 100%;
            //width: auto !important;
          }
          img {
            
           
          }
        }
      }
    }
  }
}

.reflection--category {
  .margins();
  padding-top: @mobile_header_height + @margin;
  position: fixed;
  display: none;
  @media @desktop {
    display: block;
    //padding-top: 0;
  }
}

/* ============================= 

          CLONE

================================ */
.clone-page:not([data-template="home"]) {
  .fade-in {
    animation: none !important;
  }
}


/* ============================= 

          STUDIOS

================================ */
#Hub {
  [data-template="studios"] {

    .studio--logo {
      margin-bottom: 1em !important;
      text-align: center;
      svg {
        margin: 0 auto;
        width: 100%;
      }
    }

    .slick {
      margin-bottom: @margin !important;
      @media @desktop {
        margin-bottom: @desktop_margin*3 !important;
      }

      .slick-list {
        overflow: visible; //so we can see captions
        margin-left: -@margin;
        margin-right: -@margin;
        .slide {
          margin: 0 @margin;
        }
      }
    }

    .slick-track{
      .flex-display();
      .flex-direction(row);
      .flex-wrap(no-wrap);
  
      .slick-slide{
          .flex-display();
          .align-items(center);
          .justify-content(center);
          height: 100%;
          object-fit: cover;
          opacity: 0;

          &.slick-current, &.slick-active {
            
            opacity: 1;
          }

          .module--caption {
            .caption();
            position: absolute;
            bottom: -1.75rem;
            left: 0;
            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
              //position: relative;
              //bottom: 0;
              left: 0;
              text-align: left;
            }
            @-moz-document url-prefix() {
              //position: relative;
              //bottom: 0;
              left: 0;
              text-align: left;
              margin-left: @margin + 4;
            }
            p {
              .caption();
            }
          }

          .studio--slide {
            text-align: center;
          }

          figure {
            height: 100%;
            width: auto;
            text-align: center;
            display: inline-block;
            position: relative;

            img {
              display: block;
              height: 100% !important;
              width: auto !important;
              margin: 0 auto;
            }
          }
          

          //logos
          svg {
            height: auto;
            width: 100%;
            @media @desktop {
              height: 100%;
              width: auto;
            }
            margin: 0 auto;
            
          }
      }
    }
    
    /*
    .slick {
      margin: 0 auto;
      @media @mobile {
        //height: auto !important;
        width: 100% !important;
      }
      svg {
        @media @mobile {
          width: 100% !important;
        }
      }
      .slick-track, .slick-list {
        overflow: visible;
      }
      .slick-slide {
        .module--caption {
          .caption();
          p {
            .caption();
          }
        }
      }
      */

      /*
      .studio--slide {
        .flex-display();
        display: flex !important;
        .flex-direction(column);
        width: auto !important;
        margin: 0 auto;
        figure {
          display: block;
          width: 100%;
        }
      }
      
    }
    */

    .module--caption {
      position: relative;
      @media @mobile {
        display: none;
      }
    }
  
    //custom centering
    [data-studio="acte"] {
      svg {
        transform: translateX(-.8rem);
        @media @desktop {
          transform: translateX(-2rem);
        }
      }
      
    }
  
    [data-studio="cast"] {
      svg {
        transform: translateX(-.8rem);
        @media @desktop {
          transform: translateX(-2rem);
        }
      }
      
    }
  
  
    svg {
      width: auto;
      //margin-bottom: 2rem;
      
      path {
        fill: #000;
        pointer-events: none;
      }
    }
    .slick-slider, .slick-list, .slick-track {
      //height: auto !important;
    }
    /*
    .slick {
      margin-bottom: @margin !important;
      @media @desktop {
        margin-bottom: @desktop_margin*3 !important;
      }
      figure {
        height: 100%;
        width: auto;
        img {
          height: 100%;
          width: auto;
          margin: 0 auto;
        }
      }
    }
    */
  }
}
