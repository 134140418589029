/* ============================= 

          HELPERS

================================ */

.font {
    font-family: 'NeueHaasGroteskDisp Pro Md', 'Helvetica', Arial, sans-serif;
    font-weight: normal;
  }
  
  .font-text {
    font-family: 'NeueHaasGroteskDisp Pro Md', 'Helvetica', Arial, sans-serif;
    font-weight: normal;
  }
  
  .no-hover {
    pointer-events: none;
  }
  
  .gray { 
    color: #000;
    //opacity: .4;
    //mix-blend-mode: multiply;   
    
    color: #fff;
    svg {
      line {
        //stroke: #bdbdbd;
        stroke: #000;
        opacity: .4;
      }
    }
    
  }
  .gray-hover { 
    color: #000; 
    opacity: 1;
    //mix-blend-mode: normal;  
  }
  
  .placeholder {
    color: @active_text !important;
  }
  
  .input-inverse {
    color: #fff;
    border-color: #fff;
    &::placeholder {
      color: rgba(255, 255, 255, .2);
    }
    
  }
  
  .hide-scrollbars {
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
    
  }
  
  .theme-gradient-down {
    position: absolute;
    left: 0;
    top: @mobile_header_height;
    width: 100%;
    height: 3.5rem;
    pointer-events: none;
    z-index: 1;
    background: rgb(255,237,0);
    background: linear-gradient(180deg, rgba(255,237,0,1) 0%, rgba(255,237,0,1) 75%, rgba(255,237,0,0) 100%);
    @media @desktop {
      top: 0;
      height: 6.5rem;
    }
  }
  
  .theme-gradient-up {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 3.5rem;
    pointer-events: none;
    z-index: 1;
    background: rgb(255,237,0);
    background: linear-gradient(0deg, rgba(255,237,0,1) 0%, rgba(255,237,0, 1) 75%, rgba(255,237,0,0) 100%);
    @media @desktop {
      height: 6.5rem;
    }
  }
  