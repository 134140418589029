.uniform__potty {
    position: absolute;
    left: -9999px;
}

@textarea_padding: .75rem 1rem;
@select_padding: .375rem .75rem;

.job-form form input, .job-form form li:not(.search-choice):not(.search-field), .job-form form select, .job-form form textarea {
    user-select: all !important;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  //border: 1px solid none;
  .h2();
  -webkit-text-fill-color: inherit;
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  transition: background-color 5000s ease-in-out 0s;
}

input[type="text"], input[type="email"], input[type="password"] { 
    
}

form {
    .alert {
        padding-bottom: 1em;
        //display: block;
    }
    button[type="submit"] {
        border-color: #000;
        color: #000;
    }
}

.job-form {
    a {
        &:hover {
            opacity: .15 !important;
            color: #000 !important;
            transition: @transition_speed_link all;
        }
    }
}

.file-wrapper {
    .flex-display();
    position: relative;

    img {
        position: absolute;
        height: 100%;
        width: auto;
        margin-left: 1rem;
    }

    label, input { white-space: nowrap; }

    .file-preview {
        width: auto;
        padding: @select_padding;
        padding-left: 1rem;
        color: @active_text;
    }
}

.select-wrapper {
    position: relative;
    
    width: 100%;
    @media @desktop {
        max-width: 75%;
    }
    svg { pointer-events: none; }
    &:hover, &:focus {
        svg {
            fill: #000;
            
            polyline {
                stroke: #000;
            }
        }
    }
    select {
        width: 100%;
        max-width: 100% !important;
        -moz-appearance:none; /* Firefox */
        -webkit-appearance:none; /* Safari and Chrome */
        appearance:none;
    }
    svg {
        position: absolute;
        width: 1rem;
        height: 100%;
        right: .75rem;
        top: 0rem;

        @media @desktop {
            width: 2rem;
            height: 100%;
            right: 1rem;
            top: .2rem;
        }
        
        polyline {
            transition: @transition_speed_link all;
        }
    }
}

.job-form {
    .margins();
    width: auto;

    //title
    h1 {
        text-align: left;
        .margins();
        padding-bottom: 1em !important;
        padding-top: 0 !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    button {
        color: @active_text;
        &:hover, &:active {
            color: #000;
        }
    }

    //social
    .social {
        //.flex-display();
        //.flex-direction(column);

        @media @desktop {
            max-width: 84%;
            input {
                width: 100%;
                max-width: 100%;
            }
        }

        input.active + input {
            margin-top: 1rem;
        }

        &.active {
            + span { display: none; }
        }

        button {
            bottom: auto;
            top: .1em;
            &:hover {
                color: #000;
                border-color: #000;
            }
        }

        //wrapper
        .social-input-wrapper {

            button {
                width: .375em;
                margin-right: .75rem;
                margin-top: .25rem;
                opacity: 1;
                svg {
                    width: 100%;
                    opacity: 1;
                    line { opacity: 1; transition: @transition_speed_link all; }
                }
            }
            
            button {
                display: none;
            }
            .social-url {
                width: auto;
                display: inline-block;
                padding: @select_padding;
                padding-right: 1em;
                border: 2px solid @active_text;
                border-radius: @rounded_unit;
                margin: 1rem 1rem 0rem 0 !important;
                transition: @transition_speed_link all;
                width: 100% !important;
            @media @desktop {
                max-width: 84%;
            }

                input {
                    pointer-events: none;
                    display: block !important;
                    //width: auto;
                    border: none;
                    
                }

                &:hover {
                    border-color: #000;
                    svg {
                        line {
                            fill: #000;
                            stroke: #000;
                        }
                    }
                }

                button, .social-url { display: block; }
                input { display: none; }
            }
        }

        input:not(.active) {
            border: none;
            border: 2px solid @active_text;
            //padding: @select_padding;
            border-radius: @rounded_unit;
            margin: .5rem 0; 
            text-indent: .75rem;
            transition: @transition_speed_link all;
            &:hover {
                border-color: #000;
                color: #000;
            }
        }

        
    }


    //to top
    .ToTop {
        margin: 2em 0;
        color: #000;
        
        &:hover {
            color: @active_text;
        }
    }

    //form
    form {
        top: 0;
        width: auto;

        //chosen 
        .chosen-container .chosen-results li {
            .h2-rte();
            height: auto;
            box-shadow: none;
            color: #000;
            &.result-selected {
                color: @active_text;
            }
        }

        .search-field {
            width: 100%;
        }

        .chosen-container-multi .chosen-choices li.search-field input[type=text] {
            .h2-rte();
            height: auto;
            box-shadow: none;
            color: @active_text;
        }

        .chosen-container {
            width: 100% !important;
            @media @desktop {
                max-width: 84%;
            }
        }
        
        //the input the tags are added to
        .chosen-choices {
            border: none;
            border-bottom: 1px solid #000;
            box-shadow: none !important;
            background: none;
            padding: 0 !important;
            @media @desktop {
                border-width: 2px;
            }
        }

        //input field
        .chosen-search-input {
            width: 100% !important;
        }

        //the tag
        .search-choice {
            .rounded();
            background: none !important;
            background-image: none !important;
            border-radius: @rounded_unit !important;
            .h2-rte();
            padding: @select_padding !important;
            margin: 0 .5rem .5rem 0 !important;
            white-space: nowrap;

            //close icon
            .search-choice-close {
                top: .5rem;
                right: .25rem;
            }
            span {
                .h2-rte(); 
                color: #000;
            }
        }

        //inputs
        input, select, textarea, li:not(.search-choice):not(.search-field) {
            .h2-rte();
            width: 100%;
            outline: none !important;
        }

        input, textarea {
            color: @active_text;
            box-shadow: none;
            -webkit-box-shadow: none;
            -moz-box-shadow: none;

            &:focus, &:active {
                color: #000;
            }
            @media @desktop {
                max-width: 84%;
            }
        }

        //radio
        input[type="radio"] {
            width: auto;
        }

        .radio-set {
            
            & > label {
                cursor: pointer;
                position: relative;
                &:hover {
                    .radiobtn {
                        border-color: #000;
                    }
                }
            }

            .radio-set-text {
                text-indent: .25em;
                .h2-rte();
                margin: 0;
                display: inline-block;
                @media @desktop {
                    text-indent: .5em;
                }
            }

            input[type="radio"] {
                visibility: hidden;
            }

            .radiobtn {
                position: absolute;
                margin: 0;
                top: -.05em;
                left: -.25em;
                height: 1.25em;
                width: 1.25em;
                //background-color: #eee;
                border: 1px solid @active_text;
                border-radius: 50%;
                transition: @transition_speed_link all;
                @media @desktop {
                    top: .05em;
                    left: -.25em;
                    height: 2.5em;
                    width: 2.5em;
                    border-width: 2px;
                }
            }

            input:checked ~ .radiobtn {
                background-color: #000;
            }

            .radio-content { display: none; margin: .5rem 0 1rem 0 !important; }
            input:checked ~ .radiobtn ~ .radio-content {
                display: block;
            }
        }

        input[type="file"] {
            border-bottom: 0 !important;
        }

        [type="file"] {
            border: 0;
            clip: rect(0, 0, 0, 0);
            height: 1px;
            overflow: hidden;
            padding: 0;
            position: absolute !important;
            white-space: nowrap;
            width: 1px;
          }
           
          [type="file"] + label {
            cursor: pointer;
            .rounded();
            color: @active_text;
            padding: @select_padding;
            border: 1px solid @active_text;
            display: inline-block;
            transition: @transition_speed_link all;
            @media @desktop {
                border-width: 2px;
            }

            margin-bottom: 0;
          }
            
          [type="file"]:focus + label,
          [type="file"] + label:hover {
              color: #000;
              border-color: #000;
          }
            
          [type="file"]:focus + label {
            outline: 1px dotted #000;
          }

        select, textarea {
            .rounded();
            border: 1px solid @active_text;
            background-image: none;
            transition: @transition_speed_link all;
            @media @desktop {
                border-width: 2px;
            }

            &:hover {
                border-color: #000;
            }
        }

        .textarea-wrapper {
            width: auto;
            .flex-display();
            
        }

        textarea {
            padding: @textarea_padding !important;
            width: 100%;
            color: @active_text;
            &:focus, &:active {
                color: #000;
            }
        }

        select {
            padding: @select_padding !important;
            color: @active_text;
            @media @desktop {
                max-width: @max_module_width_desktop;
            }
            &:focus, &:active {
                color: #000;
            }
        }

        //labels
        label {
            display: block;
            margin-bottom: .25rem;
        }

        ol {
            .margins();
            padding-left: 0 !important;
            padding-right: 0 !important;
            margin-bottom: 0 !important; 
            padding-bottom: 0 !important;
            //padding-top: 0 !important;
            display: table;
            list-style: none;
            padding-top: 1em !important;
            //padding: .67em 0;
            .h2-rte();

            @media @desktop {
                //margin: 0 .67em;
            }

            & > li {
                
                margin-bottom: @margin !important;
                display: table-row;
                counter-increment: table-ol; 

                div.form-set {
                    display: table-cell;
                    .margins();
                }

                @media @desktop {
                    margin-bottom: @desktop_margin;
                }

                &:before {
                    content: counter(table-ol) ".";
                    display: table-cell;
                    padding-right: 0em;
                    text-align: left;
                    @media @desktop {
                        padding-right: 0.4em;
                    }
                }

                &:after {
                    content:'';
                    display: block;
                    width: 100%;
                    height: 1.5rem;
                }

                &:last-of-type {
                    margin-bottom: 0;
                    padding-bottom: 0;
                }

                span {
                    .p-smaller();
                    display: block;
                    margin-top: .5rem;
                    &.radio-set-text { max-width: 100%; }
                    @media @desktop {
                        max-width: 52%;
                    }
                    
                }
            }

            //form actions submit and preview
            li.form-actions {
                //display: block;
                padding-top: 0 !important;

                &:before {
                    visibility: hidden;
                }
                button {
                    .h2-rte();
                    .rounded();
                    color: #000;
                    position: relative;
                    top: 0;
                    display: inline-block;
                    float: left;
                    border: 1px solid @active_text;
                    color: @active_text;
                    //border-radius: @rounded_unit;
                    padding: @select_padding;
                    margin-right: 1rem;
                    //margin-top: .75rem;
                    @media @desktop {
                        border-width: 2px;
                    }
                    &:hover {
                        border-color: #000;
                    }
                }
            }
        }
    }
}



.form-input {
    position: relative;
    button#submit {
        position: absolute;
        bottom: .1em;
        right: 0;
        border: none;
        top: auto;
        margin: 0;
        padding-right: 0;
        padding-bottom: 0;
    }
}


[data-type="control_dropdown"] .form-input, [data-type="control_dropdown"] .form-input-wide {
    width: 100% !important;
    @media @desktop {
        max-width: 60%;
    }
}


/*
.job-form {
    .margins();

    
// overrides for jotform
form {
    .form-all {
        width: 100% !important;
        .extended {
            .flex-display();
        }
        .form-line {
            
            &.form-line-active {
                background: none;
                input {
                    box-shadow: none;
                }
                textarea {
                    border-color: #000;
                    box-shadow: none
                }
            }
        }
        label.form-sub-label {
            .small();
            color: #000;
        }
        .form-header-group .form-header {
            h2 { .h2-rte(); }
        }
        h2, label.form-label, input, select, textarea {
            .h2-rte();
            color: #000 !important;
        }
        input, select, textarea {
            width: 100% !important;
            border-radius: 16px;
            box-shadow: none;
            outline: none;
            padding: 0;
            border-width: 2px;
            
        }
        select  {
            opacity: .25;
            &:hover, &:active, &:focus {
                opacity: 1;
            }
        }
        .qq-uploader {
            .qq-upload-button {
                opacity: .25;
            }
            &:hover {
                .qq-upload-button {
                    opacity: 1;
                }  
            }
        }
        input[type="text"],
        input[type="email"] {
            border: none;
            border-bottom: 2px solid #000;
            border-radius: 0px;
        }
        .form-submit-button, .qq-upload-button {
            position: relative !important;
            border: 2px solid #000;
            .h2-rte();
            color: #000;
            cursor: pointer;
            background: none;
            box-shadow: none;
            border-radius: 16px;
        }
    }

    //required styles
    .form-required {
        margin: 0;
        margin-left:-.25rem;
        color: #000 !important;
    }
}





    .formFooter,
    [data-type="control_head"],
    [data-type="control_divider"] {
        display: none !important;
    }
    [data-type="control_button"] {
        display: block;
    }
    [data-type="control_email"] {
        label {
            display: none;
        }
    }
    .form-sub-label-container {
        display: block;
    }
    .form-line-error div.form-error-message {
        background-color: red;
        border-radius: 0;
    }
    li.form-line-error input:not(#coupon-input), li.form-line-error select, li.form-line-error textarea, li.form-line-error .form-validation-error {
        border-color: red;
    }
    .form-line-error div.form-error-message .form-error-arrow {
        border-bottom-color: red;
    }
    
    iframe {
        width: 100% !important;
        @media @desktop {
            max-width: 60%;
        }
    }
    h1 {
        text-align: left;
    }
    ul {
        margin: 0 @desktop_margin * 2;
        padding: 0 @desktop_margin * 2;
        li {
            display: list-item;
            list-style-type: decimal;
            list-style-position: center;
            margin: 0;
            padding: 0;
            .h2-rte();
            margin-bottom: @desktop_margin;
            color: #000;
            &.submit-button {
                display: block;
            }
        }
    }

    //to top
    .ToTop {
        &:hover {
            color: #000;
            opacity: .2;
        }
    }
}

*/