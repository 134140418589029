
/* ============================= 

          FONTS

================================ */


@font-face {
    font-family: 'NeueHaasGroteskDisp Pro Md';
    src: local('NeueHaasGroteskDisp Pro Md'), url('/assets/fonts/NHaasGroteskDSPro-65Md.woff2') format('woff2'), url('/assets/fonts/NHaasGroteskDSPro-65Md.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }
  