.lockup {
    margin: 2em 0 !important;
    text-align: center;
    pointer-events: none;
    .slick-track { transition-duration: 0s !important; }
    ul {
        margin: 0 auto !important;
        padding: 0;
        text-align: center;
        max-width: 400px;
        @media @desktop {
            max-width: 85%;
        }
        &.lockup--top {
            padding-bottom: .675em !important;
                margin-bottom: .45em !important;
                border-bottom: .1em solid #000;
        }
        &.lockup--bottom {
            padding-top:.25em;
        }
        &.slick-initialized {
            li {
                display: block;
                //line-height: 1.5;
            }
        }
        li, div {
            text-align: center;
            margin: 0;
            padding: 0;
            margin-bottom: 0 !important;
            display:none;
            &:first-child {
                display: block;
            }
        }
        
    }
}